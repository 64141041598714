.maintainencemain {
    padding: 152px 20px;
    min-height: 100vh;
    position: relative;
    background: url('../../assets/kycbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .maintainenceinner {
        display: flex;
        padding: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.03);
        max-width: 776px;
        width: 100%;
        margin: 0 auto;

        .attentionimg{
            margin-bottom: 40px;
            z-index: 99;
            position: relative;
        }

        .mainhead {
            color: #FFF;
            text-align: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 10px;
            z-index: 99;
            position: relative;
        }

        .mainpara {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 99;
            position: relative;
        }
    }
}

@media(max-width:600px){
    .maintainencemain .maintainenceinner .mainhead{
        font-size: 24px;
    }
    .maintainencemain .maintainenceinner .mainpara{
        font-size: 16px;
    }
    .maintainencemain .maintainenceinner{
        padding: 20px;
    }
}