.claimallmain {
    padding: 123px 0px 50px;

    .claimallinnerhead {
        color: #FFF;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    .claimallinner {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .claimallleft {
            max-width: 687px;
            width: 100%;

            .claimallleftinner {
                border: 1px solid #FFF;
                background: #0D0D0D;
                padding: 15px;
                margin-bottom: 8px;

                .checkboxmain {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;
                    margin-top: 20px;

                    .clear {
                        clear: both;
                    }

                    .checked {
                        box-shadow: 0px 0px 0px 2px #fff !important;
                        transition: 300ms ease;
                    }

                    .checkBox {
                        display: block;
                        cursor: pointer;
                        width: 24px;
                        height: 24px;
                        border: 3px solid #000;
                        position: relative;
                        overflow: hidden;
                        box-shadow: 0px 0px 0px 2px #FFF;
                        transition: 300ms ease;
                        background-color: #000;
                    }

                    .checkBox div {
                        width: 60px;
                        height: 60px;
                        background-color: #FFF;
                        top: -52px;
                        left: -52px;
                        position: absolute;
                        transform: rotateZ(45deg);
                        z-index: 100;
                    }

                    .checkBox input[type=checkbox]:checked+div {
                        left: -10px;
                        top: -10px;
                    }

                    .checkBox input[type=checkbox] {
                        position: absolute;
                        left: 50px;
                        visibility: hidden;
                    }

                    .transition {
                        transition: 300ms ease;
                    }

                    .checkboxtexts {
                        .checkboxhead {
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            text-transform: uppercase;
                            display: flex;
                            align-items: center;
                            gap: 12px;
                            margin-bottom: 9px;

                            .greenspan {
                                color: #28E664;
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 120%;
                                letter-spacing: -0.1px;
                                border: 1px solid rgba(40, 230, 100, 0.60);
                                background: rgba(40, 230, 100, 0.10);
                                display: flex;
                                padding: 4px 6px;
                                justify-content: center;
                                align-items: center;
                                max-width: fit-content;
                            }

                            .redspan {
                                border: 1px solid rgba(255, 131, 0, 0.60);
                                background: rgba(255, 131, 0, 0.10);
                                color: #FF8300;
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 120%;
                                display: flex;
                                padding: 4px 6px;
                                justify-content: center;
                                align-items: center;
                                letter-spacing: -0.1px;
                            }
                        }

                        .checkboxpara {
                            color: #FFF;
                            font-size: 9px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            text-transform: uppercase;
                        }
                    }
                }

                .claimalllefthead {
                    color: #FFF;
                    margin-bottom: 20px;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                }

                .claimtoken {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    cursor: pointer;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .claimtokenleft {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        justify-content: flex-start;

                        .claimtokenimg {
                            display: flex;
                            width: 76px;
                            height: 76px;
                            justify-content: center;
                            align-items: center;
                            background: #FFF;
                            object-fit: cover;
                            object-position: center;

                            .claiminnerimg {
                                width: 100%;
                                height: 100%;
                                background: #FFF;
                                object-fit: cover;
                                object-position: center;
                            }
                        }

                        .tokenlefthead {
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                        }
                    }

                    .claimtokenrighthead {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                        text-align: end;

                        .xspan {
                            font-weight: 400 !important;
                        }
                    }
                }
            }
        }

        .claimallright {
            border: 1px solid #FFF;
            background: #0D0D0D;
            padding: 15px;
            width: 100%;
            max-width: 543px;
            position: sticky;
            top: 80px;

            .claimallrighthead {
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 20px;
            }

            .checkboxmain {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 15px;
                margin-bottom: 40px;

                .clear {
                    clear: both;
                }

                .checked {
                    box-shadow: 0px 0px 0px 2px #fff !important;
                    transition: 300ms ease;
                }

                .checkBox {
                    display: block;
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    border: 3px solid #000;
                    position: relative;
                    overflow: hidden;
                    box-shadow: 0px 0px 0px 2px #FFF;
                    transition: 300ms ease;
                    background-color: #000;
                }

                .checkBox div {
                    width: 60px;
                    height: 60px;
                    background-color: #FFF;
                    top: -52px;
                    left: -52px;
                    position: absolute;
                    transform: rotateZ(45deg);
                    z-index: 100;
                }

                .checkBox input[type=checkbox]:checked+div {
                    left: -10px;
                    top: -10px;
                }

                .checkBox input[type=checkbox] {
                    position: absolute;
                    left: 50px;
                    visibility: hidden;
                }

                .transition {
                    transition: 300ms ease;
                }

                .maincheckboxpara {
                    color: #FFF;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    margin-top: -4px;
                }
            }

            .summarymain {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 14px;

                .summarypara {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }

                .summaryhead {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                }
            }

            .totalmain {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 20px 0px 40px;
                border-top: 1px solid #3D3D3D;
                margin-top: 24px;

                .totalpara {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }

                .totalhead {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    text-align: end;
                }
            }

            .claimbtn {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 2px 2px 0px 0px #FFF;
                display: flex;
                padding: 17px 25px;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                width: 100%;
            }
        }
    }
}

.tablemodal {
    .modal-dialog {
        max-width: 749px;

        .modal-header {
            margin-bottom: 30px;
        }

        .mainrewardsdiv {
            border: 1px solid #FFF;

            .rewardmain {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rewardinnerpara {
                    display: flex;
                    padding: 28px 30px;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    background: #000;
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                    backdrop-filter: blur(3px);
                }
            }
        }

        .datatable {
            .main-heading {
                margin: 30px 0;

                h6 {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.44px;
                }
            }

            .table-responsive {
                border: 1px solid #FFF;
                background: #000;

                &::-webkit-scrollbar {
                    display: none !important;
                }

                .table {
                    margin: 0;
                }

                th {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                    vertical-align: middle !important;
                    background: #000;
                    padding: 28px 30px;
                    backdrop-filter: blur(3px);
                    white-space: nowrap;
                    border: none;
                }

                td {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                    vertical-align: middle !important;
                    background: #000;
                    padding: 28px 30px;
                    backdrop-filter: blur(3px);
                    white-space: nowrap;
                    border: none;

                    .twice-text {
                        h6 {
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 21px;
                            /* 175% */
                            letter-spacing: 0.24px;
                        }

                        p {
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            /* 175% */
                            letter-spacing: 0.24px;
                            margin-top: 2px;
                        }
                    }

                    .forend {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .common-btn {
                            padding: 13px 25px;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 100%;
                        }
                    }
                }

                .footer-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 14px 20px 13px 20px;
                    border: 1px solid #FFF;
                    background: #000;
                    backdrop-filter: blur(3px);

                    .left-f {
                        h6 {
                            color: #A2A4A6;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                        }
                    }

                    .right-f {
                        .page-link:focus {
                            box-shadow: none;
                            background-color: unset;
                        }

                        .page-link:hover {
                            box-shadow: none;
                            background-color: unset;
                        }

                        .page-link {
                            border: none;
                            color: #A2A4A6;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            background-color: transparent;

                            &.active {
                                background: #fff;
                                color: #000;
                            }
                        }

                        .page-item:first-child .page-link {
                            color: #A2A4A6;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                        }
                    }
                }
            }

            .dropdown {
                .dropdown-toggle {
                    background-color: transparent;
                    border: none;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    border: 1px solid #FFF;
                    background: #000;
                    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
                    padding: 0;

                    a {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;
                        padding: 14px 15px;
                    }
                }
            }
        }


        .filter-btn {
            display: flex;
            flex-direction: column;
            width: 11px;
            margin-left: 10px;
        }

        .parent-tag {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }


        .page-item.active {
            .page-link {
                background: linear-gradient(180deg, #3A7BD5 0%, #00D2FF 100%);
                border-radius: 5px;
                color: black !important;
            }
        }

        .active-pag {
            background: #fff !important;
            color: #000 !important;
        }
    }
}

@media (max-width:992px) {
    .claimallmain .claimallinner {
        flex-direction: column;
    }

    .claimallmain .claimallinner .claimallleft {
        max-width: 100%;
    }

    .claimallmain .claimallinner .claimallright {
        max-width: 100%;
        position: unset;
    }
}


@media (max-width:650px) {
    .tablemodal .modal-dialog .mainrewardsdiv .rewardmain .rewardinnerpara {
        padding: 0px;
    }

    .tablemodal .modal-dialog .mainrewardsdiv .rewardmain {
        width: 100%;
    }

    .tablemodal .modal-dialog .mainrewardsdiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 10px;
        padding: 28px 18px;
    }

    .table-responsive {
        display: none;
    }

    .accmblview {
        display: block !important;
        border: 1px solid #FFF;
        background: #000;
        backdrop-filter: blur(3px);

        .main-twice-text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 28px 25px;

            .accmblviewhead {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.28px;
                text-transform: uppercase;
            }
        }



        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                background: url("../../assets/arrow.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
                position: absolute;
                top: 28px;
                right: 25px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;


                    .accordion-button {
                        border-top: 1px solid #FFF;
                        border-bottom: 1px solid #FFF;
                        background: var(--login-bg);
                        padding: 26px 25px;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 100%;

                            .acctext {
                                color: #FFF;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 100%;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 0px 25px;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 0px;
                        border-bottom: 1px solid #1C1C1C;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                            text-transform: uppercase;
                        }

                        .textright {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                        }
                    }
                }
            }
        }

        .twice-text {
            h6 {
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
            }

            p {
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
                margin-top: 2px;
            }
        }

        .view-btn {
            border: 1px solid #FFF;
            background-color: transparent;
            padding: 13px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 14px */
            width: 100%;
        }
    }

    .accmblview .accordion .accordion-item .accordion-header .accordion-button {
        box-shadow: none !important;
    }

    .footer-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        // border-top: 1px solid #F2F2F2;
        background-color: transparent;

        .left-f {
            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }

        .right-f {
            .page-link:focus {
                box-shadow: none;
                background-color: unset;
            }

            .page-link:hover {
                box-shadow: none;
                background-color: unset;
            }

            .page-link {
                border: none;
                color: #A2A4A6;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 15px */
                background-color: transparent;

                &.active {
                    background: #fff;
                    color: #000;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }
    }

    .dashboardetail .auctiondetailhead {
        font-size: 20px;
    }
}

@media (max-width:600px) {
    .claimallmain .claimallinner .claimallleft .claimallleftinner .claimalllefthead {
        font-size: 16px;
    }

    .claimallmain .claimallinner .claimallleft .claimallleftinner .claimtoken .claimtokenleft .tokenlefthead {
        font-size: 12px;
    }

    .claimallmain .claimallinner .claimallleft .claimallleftinner .claimtoken .claimtokenleft {
        gap: 15px;

        svg {
            display: none;
        }
    }

    .claimallmain .claimallinner .claimallleft .claimallleftinner .claimtoken .claimtokenleft .claimtokenimg {
        height: 50px;
        width: 50px;
    }

    .claimallmain .claimallinner .claimallleft .claimallleftinner .claimtoken .claimtokenrighthead {
        font-size: 12px;
    }

    .claimallmain .claimallinner .claimallleft .claimallleftinner .checkboxmain .checkboxtexts .checkboxhead {
        font-size: 12px;
    }

    .claimallmain .claimallinner .claimallright .claimallrighthead {
        font-size: 16px;
    }

    .claimallmain .claimallinner .claimallright .summarymain .summarypara {
        font-size: 12px;
    }

    .claimallmain .claimallinner .claimallright .summarymain .summaryhead {
        font-size: 12px;
    }

    .claimallmain .claimallinner .claimallright .totalmain .totalpara {
        font-size: 12px;
    }

    .claimallmain .claimallinner .claimallright .totalmain .totalhead {
        font-size: 14px;
    }
    .claimallmain .claimallinner .claimallright .checkboxmain .maincheckboxpara{
        font-size: 12px;
    }
}