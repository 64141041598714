.kyc {
    padding: 112px 15px 150px;
    position: relative;
    min-height: 100vh;
    background: url('../../assets/kycbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .kyctimeline {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35px;

        .whitebox {
            border: 1px solid #FFF !important;
            background: #000 !important;
            box-shadow: 2px 2px 0px 0px #FFF !important;
            color: #FFF !important;
        }

        .whiteline {
            background: #FFF !important;
        }

        .timelineline {
            width: 81px;
            height: 2px;
            background: #3E3E3E;
        }

        .timelineinner {
            border: 1px solid #3E3E3E;
            background: #0F0F0F;
            display: flex;
            width: 60px;
            padding: 16px 21px;
            justify-content: center;
            align-items: center;
            color: #393939;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }
    }

    .innerkycmain {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 78px;

        .innerkyc {
            background: rgba(255, 255, 255, 0.03);
            max-width: 607px;
            width: 100%;
            padding: 20px;

            .green {
                color: #28E664 !important;
            }

            .red{
                color: #FE0000 !important;
            }

            .kychead {
                color: #FFF;
                text-align: center;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 20px;
            }

            .kycpara {
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 40px;
            }

            .checkboxmain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
                margin-bottom: 10px;

                .clear {
                    clear: both;
                }

                .checked {
                    box-shadow: 0px 0px 0px 2px #fff !important;
                    transition: 300ms ease;
                }

                .checkBox {
                    display: block;
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    border: 3px solid #000;
                    position: relative;
                    overflow: hidden;
                    box-shadow: 0px 0px 0px 2px #FFF;
                    transition: 300ms ease;
                    background-color: #000;
                }

                .checkBox div {
                    width: 60px;
                    height: 60px;
                    background-color: #FFF;
                    top: -52px;
                    left: -52px;
                    position: absolute;
                    transform: rotateZ(45deg);
                    z-index: 100;
                }

                .checkBox input[type=checkbox]:checked+div {
                    left: -10px;
                    top: -10px;
                }

                .checkBox input[type=checkbox] {
                    position: absolute;
                    left: 50px;
                    visibility: hidden;
                }

                .transition {
                    transition: 300ms ease;
                }

                .maincheckboxpara {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .proceedbtn {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 4px 4px 0px 0px #FFF;
                display: flex;
                padding: 19px 40px;
                justify-content: center;
                align-items: center;
                color: #FFF;
                width: 100%;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                margin-top: 40px;
            }
        }
    }

    .lastkyctexts {
        max-width: 567px;
        width: 100%;
        margin: 0 auto;

        .contactpara {
            color: #FFF;
            text-align: center;
            margin-bottom: 14px;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .emailpara {
            background: rgba(255, 255, 255, 0.02);
            display: flex;
            padding: 10px;
            align-items: center;
            justify-content: center;
            color: #FFF;
            text-align: center;
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .lasttexts {
        max-width: 567px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .kyclastpara {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 21px;
        }

        .kyclink {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline !important;
        }

    }

    .lastpara {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: absolute;
        bottom: 85px;
        left: 50%;
        transform: translate(-50%, 0%);

        .lastparalink {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline !important;
        }
    }
}

@media(max-width:600px) {
    .kyc .innerkycmain .innerkyc .kychead {
        font-size: 18px;
    }

    .kyc .innerkycmain .innerkyc .kycpara {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .kyc .innerkycmain {
        margin-bottom: 30px;
    }

    .kyc .lastpara {
        position: unset;
        transform: unset;
    }

    .kyc .lastpara {
        font-size: 12px;
        margin-top: 30px;
    }

    .kyc .lastpara .lastparalink {
        font-size: 12px;
    }

    .kyc .lasttexts .kyclastpara {
        font-size: 14px;
    }

    .kyc .lasttexts .kyclink {
        font-size: 14px;
    }

    .kyc .lastkyctexts .contactpara {
        font-size: 14px;
    }

    .kyc .lastkyctexts .emailpara {
        font-size: 14px;
    }
}


.material-textfield {
    position: relative;
    width: 474px;
    padding: 7px ;
}