.kycmain {
    padding: 112px 15px 85px;
    position: relative;
    min-height: 100vh;
    background: url('../../assets/kycbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .innerkycmain {
        display: flex;
        justify-content: center;
        align-items: center;

        .innerkyc {
            background: rgba(255, 255, 255, 0.03);
            max-width: 607px;
            width: 100%;
            padding: 20px;

            .kychead {
                color: #FFF;
                text-align: center;
                font-size: 21px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 20px;
            }

            .checkboxmain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
                margin-bottom: 10px;

                .clear {
                    clear: both;
                }

                .checked {
                    box-shadow: 0px 0px 0px 2px #fff !important;
                    transition: 300ms ease;
                }

                .checkBox {
                    display: block;
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    border: 3px solid #000;
                    position: relative;
                    overflow: hidden;
                    box-shadow: 0px 0px 0px 2px #FFF;
                    transition: 300ms ease;
                    background-color: #000;
                }

                .checkBox div {
                    width: 60px;
                    height: 60px;
                    background-color: #FFF;
                    top: -52px;
                    left: -52px;
                    position: absolute;
                    transform: rotateZ(45deg);
                    z-index: 100;
                }

                .checkBox input[type=checkbox]:checked+div {
                    left: -10px;
                    top: -10px;
                }

                .checkBox input[type=checkbox] {
                    position: absolute;
                    left: 50px;
                    visibility: hidden;
                }

                .transition {
                    transition: 300ms ease;
                }

                .maincheckboxpara {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .proceedbtn {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 4px 4px 0px 0px #FFF;
                display: flex;
                padding: 19px 40px;
                justify-content: center;
                align-items: center;
                color: #FFF;
                width: 100%;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                margin-top: 40px;
            }
        }
    }

    .lastpara {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: absolute;
        bottom: 85px;
        left: 50%;
        transform: translate(-50%, 0%);

        .lastparalink {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline !important;
        }
    }
}

@media(max-width:600px){
    .kycmain .lastpara{
        position: unset;
        transform: unset;
    }
    .kycmain .lastpara{
        font-size: 12px;
        margin-top: 30px;
    }
    .kycmain .lastpara .lastparalink{
        font-size: 12px;
    }
    .kycmain .innerkycmain .innerkyc .kychead{
        font-size: 16px;
    }
}