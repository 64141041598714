.mainheader {
    border-bottom: 1px solid #FFF;
    background: #000;
    backdrop-filter: blur(12px);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    .innerheader {
        padding: 16px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dropdown {
            width: 100%;

            .newbtn {
                border: 1px solid #FFF;
                background: #FFF;
                border-radius: 0 !important;
                display: flex;
                padding: 8px;
                align-items: center;
                gap: 10px;
                color: #000;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                transition: background 0.5s ease-in-out, background 0.5s ease-in-out;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                border: 1px solid #FFF;
                background: #0D0D0D;
                min-width: 629px;
                width: 100%;
                border-radius: 0px !important;
                margin-top: 22px;
                padding: 0px !important;
                overflow-y: auto;
                transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;

                &::-webkit-scrollbar{
                    display: none;
                }

                &.show {
                    animation: expandHeight 0.5s ease-in-out forwards;
                    max-height: 0;
                }

                @keyframes expandHeight {
                    from {
                        max-height: 0;
                        opacity: 0.2;
                    }

                    to {
                        opacity: 1;
                        max-height: 85vh;
                        /* Large enough to fit content */
                    }
                }

                @keyframes expandHeight1 {
                    from {
                        opacity: 1;
                        max-height: 85vh;
                        /* Large enough to fit content */
                    }

                    to {
                        max-height: 0;
                        opacity: 0.2;
                    }
                }

                .kycmaindrop {
                    padding: 20px;

                    .kycdrophead {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                        margin-bottom: 13px;
                    }

                    .kyclistmain {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-bottom: 13px;

                        .serialkyc {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                        }

                        .kyclistpara {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;

                            .linkbold {
                                color: #FFF;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 140%;
                                text-decoration-line: underline !important;
                                overflow-wrap: anywhere;
                            }
                        }
                    }

                    .importantnotemain {
                        padding: 10px;
                        border: 1px solid rgba(255, 131, 0, 0.60);
                        background: rgba(255, 131, 0, 0.10);

                        .importanthead {
                            color: #FF8300;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 140%;
                            margin-bottom: 10px;
                        }

                        .importantpara {
                            color: #FF8300;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                        }
                    }
                }
            }

        }


        .disconnectbtn {
            color: #FE0000;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.015px;
            border: 1px solid #FE0000;
            background: #000;
            display: flex;
            padding: 13px 10px;
            justify-content: center;
            align-items: center;
        }
    }
}


.mainheader {
    .twice-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }
}

.faq-btn {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

@media(max-width:700px) {
    @keyframes expandHeight {
        from {
            max-height: 0;
            opacity: 0.2;
        }

        to {
            opacity: 1;
            max-height: 550px;
            /* Large enough to fit content */
        }
    }

    @keyframes expandHeight1 {
        from {
            opacity: 1;
            max-height: 550px;
            /* Large enough to fit content */
        }

        to {
            max-height: 0;
            opacity: 0.2;
        }
    }
    .mainheader .innerheader .dropdown .dropdown-menu {
        min-width: 400px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            display: none !important;
        }
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kyclistmain .kyclistpara {
        font-size: 12px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kyclistmain .serialkyc {
        font-size: 12px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kyclistmain {
        margin-bottom: 8px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop {
        padding: 10px 15px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .importantnotemain .importanthead {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kycdrophead {
        font-size: 14px;
        margin-bottom: 8px;
    }
}

@media(max-width:420px) {
    .mainheader .innerheader .dropdown .dropdown-menu {
        min-width: 350px;
    }
}

@media(max-width:375px) {
    .mainheader .innerheader .dropdown .dropdown-menu {
        min-width: 320px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kyclistmain .kyclistpara {
        font-size: 10px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kyclistmain .serialkyc {
        font-size: 10px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kyclistmain {
        margin-bottom: 5px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop {
        padding: 5px 10px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .importantnotemain .importanthead {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .kycdrophead {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .mainheader .innerheader .dropdown .dropdown-menu .kycmaindrop .importantnotemain .importantpara{
        font-size: 10px;
    }
}